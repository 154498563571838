<template>
  <section>
    <!-- banner start -->
    <!-- ================ -->
    <div class="banner">
      <el-carousel interval="60000" indicator-position="none" height="469px">
        <el-carousel-item>
          <!-- main image -->
          <img src="/images/banner-1.jpg" alt="slidebg1" data-bgposition="center top" data-bgfit="cover" data-bgrepeat="no-repeat">
          <!-- LAYER NR. 1 -->
          <div
            class="tp-caption caption-1"
            data-x="0"
            data-y="70"
            data-speed="600"
            data-start="1200"
            data-end="9400"
            data-endspeed="600"
          >{{ $t('header.banner.item1') }}
          </div>
        </el-carousel-item>
        <el-carousel-item>
          <!-- main image -->
          <img src="/images/banner-2.jpg" alt="slidebg1" data-bgposition="center top" data-bgfit="cover" data-bgrepeat="no-repeat">
          <!-- LAYER NR. 1 -->
          <div
            class="tp-caption caption-2"
            data-x="0"
            data-y="70"
            data-speed="600"
            data-start="1200"
            data-end="9400"
            data-endspeed="600"
          >{{ $t('header.banner.item2') }}
          </div>
        </el-carousel-item>
        <el-carousel-item>
          <!-- main image -->
          <img src="/images/banner-3.jpg" alt="kenburns" data-bgposition="left center" data-kenburns="on" data-duration="10000" data-ease="Linear.easeNone" data-bgfit="100" data-bgfitend="115" data-bgpositionend="right center">
          <!-- LAYER NR. 1 -->
          <div
            class="tp-caption caption-3"
            data-x="0"
            data-y="70"
            data-speed="600"
            data-start="1200"
            data-end="9400"
            data-endspeed="600"
          >{{ $t('header.banner.item3') }}
          </div>
        </el-carousel-item>
      </el-carousel>
    </div>
    <!-- banner end -->
    <!-- section start -->
    <!-- ================ -->
    <div class="section clearfix">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <div class="row">
              <div class="col-md-8">
                <h2 class="text-center">{{ $t('body.aboutUs') }}</h2>
                <div class="separator" />
                <p class="lead text-center">{{ $t('body.aboutUsSlogan') }}</p>
                <br>
                <h2 class="title" />
                <div class="row" style="padding-right: 40px; padding-left: 40px; font-size: 13px; letter-spacing: 1px">
                  <p>
                    <!--                    <img src="/images/section-image-2.png" align="left" width="220" hspace="5" vspace="5" alt="">-->
                    {{ $t('introduction.p1') }}
                  </p>
                  <p>{{ $t('introduction.p2') }}</p>
                  <p>{{ $t('introduction.p3') }}</p>
                  <p>{{ $t('introduction.p4') }}</p>
                  <p>{{ $t('introduction.p5') }}</p>
                </div>
                <p />
                <div class="space hidden-md hidden-lg" />
              </div>
              <div class="col-md-4">
                <div id="accordion" class="panel-group panel-dark" style="margin-left: 30px; margin-top: 90px">
                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <a data-toggle="collapse" data-parent="#accordion" href="#collapseOne" class="collapsed">
                          <i class="fa fa-chain" />{{ $t('body.certificate_query.title') }}
                        </a>
                      </h4>
                    </div>
                    <div id="collapseOne" class="panel-collapse collapse in">
                      <div class="panel-body">
                        <ul class="list-icons">
                          <li><i class="icon-link pr-10" /> <el-link target="_blank" :href="$t('body.certificate_query.srrc.url')">{{ $t('body.certificate_query.srrc.label') }}</el-link> </li>
                          <li><i class="icon-link pr-10" /> <el-link target="_blank" :href="$t('body.certificate_query.nal.url')">{{ $t('body.certificate_query.nal.label') }}</el-link> </li>
                          <li><i class="icon-link pr-10" /> <el-link target="_blank" :href="$t('body.certificate_query.ccc.url')">{{ $t('body.certificate_query.ccc.label') }}</el-link> </li>
                          <li><i class="icon-link pr-10" /> <el-link target="_blank" :href="$t('body.certificate_query.nmpa.url')">{{ $t('body.certificate_query.nmpa.label') }}</el-link> </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <a data-toggle="collapse" data-parent="#accordion" href="#collapseTwo" class="collapsed">
                          <i class="fa fa-chain" />{{ $t('header.menu.service_item.project') }}
                        </a>
                      </h4>
                    </div>
                    <div id="collapseTwo" class="panel-collapse collapse">
                      <div class="panel-body">
                        <ul class="list-icons">
                          <li><i class="icon-link pr-10" /> <router-link target="_blank" to="/service/Project">{{ $t('header.menu.service_item.project') }}</router-link> </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <a data-toggle="collapse" data-parent="#accordion" href="#collapseThree" class="collapsed">
                          <i class="fa fa-envelope" />{{ $t('body.contact') }}
                        </a>
                      </h4>
                    </div>
                    <div id="collapseThree" class="panel-collapse collapse">
                      <div class="panel-body">
                        <ul class="list-icons">
                          <li><i class="icon-check pr-10" /> {{ $t('body.business_consulting') }}: 010-88820266</li>
                          <li><i class="icon-check pr-10" /> {{ $t('body.sample_query') }}: 010-88820266</li>
                          <li><i class="icon-check pr-10" /> {{ $t('body.email') }}: <a href="mailto:info@csptech.net">info@csptech.net</a></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <a data-toggle="collapse" data-parent="#accordion" href="#collapseFour" class="collapsed">
                          <i class="fa fa-envelope" />{{ $t('footer.contact_us') }}
                        </a>
                      </h4>
                    </div>
                    <div id="collapseFour" class="panel-collapse collapse">
                      <div class="panel-body">
                        <el-form
                          ref="contact_form"
                          class="contact-form"
                          :model="contact_form"
                        >
                          <el-form-item label="" prop="name">
                            <el-input v-model="contact_form.name" :placeholder="$t('footer.name')" />
                          </el-form-item>
                          <el-form-item label="" prop="company">
                            <el-input v-model="contact_form.company" :placeholder="$t('footer.company')" />
                          </el-form-item>
                          <el-form-item label="" prop="contactNo">
                            <el-input v-model="contact_form.contactNo" :placeholder="$t('footer.mobile')" />
                          </el-form-item>
                          <el-form-item label="" prop="email">
                            <el-input v-model="contact_form.email" :placeholder="$t('footer.email')" />
                          </el-form-item>
                          <el-form-item label="" prop="content">
                            <el-input v-model="contact_form.content" type="textarea" :placeholder="$t('footer.message')" />
                          </el-form-item>
                          <el-form-item>
                            <input type="button" style="background-color: #0b0b0b; border: #0b0b0b" :value="$t('footer.send')" class="btn btn-default" @click="submitContact">
                          </el-form-item>
                        </el-form>
                      </div>
                    </div>
                  </div>
                  <!--
                  <div class="panel panel-default">
                    <div class="panel-heading">
                      <h4 class="panel-title">
                        <a data-toggle="collapse" data-parent="#accordion" href="#collapseOne">
                          <i class="fa fa-comment" />{{ $t('body.online_consultant') }}
                        </a>
                      </h4>
                    </div>
                    <div id="collapseOne" class="panel-collapse collapse in">
                      <div class="panel-body">
                        <ul class="social-links colored large">
                          <li class="weixin"><a target="_blank" href="#"><i class="fa fa-weixin" /></a></li>
                          <li class="qq"><a target="_blank" href="#"><i class="fa fa-qq" /></a></li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  -->
                </div>
              </div>
            </div>
            <br>
          </div>
        </div>
      </div>
    </div>
    <!-- section end -->

    <!-- section start -->
    <!-- ================ -->
    <div class="section footer-top clearfix">
      <div class="container">
        <div class="row">
          <div class="col-md-12">
            <slick
              ref="slick"
              class="clients"
              :options="slickOptions"
            >
              <a href="javascript:void(0)"><img src="images/client-1.png" alt=""></a>
              <a href="javascript:void(0)"><img src="images/client-2.png" alt=""></a>
              <a href="javascript:void(0)"><img src="images/client-3.png" alt=""></a>
              <a href="javascript:void(0)"><img src="images/client-4.png" alt=""></a>
              <a href="javascript:void(0)"><img src="images/client-5.png" alt=""></a>
              <a href="javascript:void(0)"><img src="images/client-6.png" alt=""></a>
              <a href="javascript:void(0)"><img src="images/client-7.png" alt=""></a>
              <a href="javascript:void(0)"><img src="images/client-8.png" alt=""></a>
            </slick>
            <!--<div class="owl-carousel clients">
              <div class="client">
                <a href="#"><img src="images/client-1.png" alt=""></a>
              </div>
              <div class="client">
                <a href="#"><img src="images/client-2.png" alt=""></a>
              </div>
              <div class="client">
                <a href="#"><img src="images/client-3.png" alt=""></a>
              </div>
              <div class="client">
                <a href="#"><img src="images/client-4.png" alt=""></a>
              </div>
              <div class="client">
                <a href="#"><img src="images/client-5.png" alt=""></a>
              </div>
              <div class="client">
                <a href="#"><img src="images/client-6.png" alt=""></a>
              </div>
              <div class="client">
                <a href="#"><img src="images/client-7.png" alt=""></a>
              </div>
              <div class="client">
                <a href="#"><img src="images/client-8.png" alt=""></a>
              </div>
            </div>-->
          </div>
          <!--<div class="col-md-6">
            <blockquote class="inline">
              <p class="margin-clear">Design is not just what it looks like and feels like. Design is how it works.</p>
              <footer><cite title="Source Title">Steve Jobs </cite></footer>
            </blockquote>
          </div>-->
        </div>
      </div>
    </div>
    <!-- section end -->
  </section>
</template>

<script>
// import $ from 'jquery'
import { submitContact } from '@/api/csp'
import Slick from 'vue-slick'
export default {
  name: 'Home',
  components: { Slick },
  data() {
    return {
      slickOptions: {
        infinite: true,
        autoplay: true,
        speed: 300,
        slidesToShow: 1,
        centerMode: true,
        variableWidth: true
      },
      contact_form: {
        name: '',
        contactNo: '',
        email: '',
        company: '',
        content: ''
      }
    }
  },
  computed: {
    contact_form_rules() {
      return {
        name: [
          { required: true, message: this.$t('body.contact_form.rule_required_name'), trigger: 'blur' }
        ],
        contactNo: [
          { required: true, message: this.$t('body.contact_form.rule_required_contactNo'), trigger: 'blur' }
        ],
        email: [
          { required: true, message: this.$t('body.contact_form.rule_required_email'), trigger: 'blur' }
        ],
        company: [
          { required: true, message: this.$t('body.contact_form.rule_required_company'), trigger: 'blur' }
        ],
        content: [
          { required: true, message: this.$t('body.contact_form.rule_required_content'), trigger: 'blur' }
        ]
      }
    }
  },
  methods: {
    submitContact() {
      let errMsg = ''
      if (this.contact_form.name.trim() === '') {
        errMsg += this.$t('body.contact_form.rule_required_name') + '<br/>'
      }
      if (this.contact_form.contactNo.trim() === '') {
        errMsg += this.$t('body.contact_form.rule_required_contactNo') + '<br/>'
      }
      // if (this.contact_form.email.trim() === '') {
      //   errMsg += this.$t('body.contact_form.rule_required_email') + '<br/>'
      // }
      if (this.contact_form.company.trim() === '') {
        errMsg += this.$t('body.contact_form.rule_required_company') + '<br/>'
      }
      if (this.contact_form.content.trim() === '') {
        errMsg += this.$t('body.contact_form.rule_required_content') + '<br/>'
      }
      if (errMsg !== '') {
        this.$alert(errMsg, '提示', { dangerouslyUseHTMLString: true })
        return
      }
      submitContact(this.contact_form).then(resp => {
        if (resp.status) {
          this.$alert('提交成功!')
          this.contact_form = {
            name: '',
            contactNo: '',
            email: '',
            company: '',
            content: ''
          }
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
    .container {
        p {
            text-indent: 2em;
            margin-bottom: 0px;
        }
      .clients {
        a {
          margin-left: 20px;
          margin-right: 20px;
        }
        img {
          height: 50px;
        }
      }
    }
    .el-carousel__item {
      img {
        background-position: center top;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        height: 469px;
      }
      .tp-caption {
        color: #475669;
        opacity: 0.75;
        line-height: 100px;
        margin: 0;
        position: absolute;
        bottom: 0px;
        left: 0px;
        font-size: 32px;
        width: 100%;
        overflow: hidden;
        height: 100px;
      }
      .caption-1 {
        color: #28AAE3;
      }
      .caption-2 {
        color: #21262F;
      }
      .caption-3 {
        color: #2E2E44;
      }
    }
    .contact-form {
      .el-form-item {
        margin-bottom: 10px;
      }
    }
    /*.el-carousel__item:nth-child(2n) {*/
    /*  background-color: #99a9bf;*/
    /*}*/
    /*.el-carousel__item:nth-child(2n+1) {*/
    /*  background-color: #d3dce6;*/
    /*}*/
</style>
